import {defineStore} from 'pinia'
import {
  CustomerActionRequest,
  ExchangeType,
  OrderStatusEnum,
  ReturnOptionEnum,
  SessionStorageKeys,
} from 'src/enums'
import {computed, ref, watch, type ComputedRef} from 'vue'
import type {
  CalculusResponse,
  LineItem,
  ElynSettings,
  GetProductVariantsParams,
  HowToReturn,
  Order,
  OrderRaw,
  ReturnPolicy,
  SimilarProducts,
  Tenant,
  Variants,
} from './interfaces'
import {OrderManager} from './managers/order'
import apiService from './apiService'
import useTracking from './composables/useTracking'

export const useStore = defineStore('store', () => {
  const isLoggedIn = ref<boolean>(
    sessionStorage.getItem(SessionStorageKeys.accessToken) !== null,
  )
  const isAppLoading = ref<boolean>(true)
  const orderRaw = ref<OrderRaw | null>(null)
  const order: ComputedRef<Order | null> = computed(() => {
    if (!orderRaw.value) return null
    const isKeepAll = orderRaw.value.lineItems.every(
      item =>
        item.lastLineItemAmount.customerRequest === CustomerActionRequest.KEEP,
    )

    const lastStatus =
      orderRaw.value?.orderStatuses[orderRaw.value.orderStatuses.length - 1]

    if (lastStatus) {
      return {
        ...orderRaw.value,
        currentStatus: lastStatus.status,
        isKeepAll,
      }
    }
    return null
  })
  const returnPolicy = ref<ReturnPolicy | null>(null)
  const tenant = ref<Tenant | null>(null)
  const lineItem = ref<LineItem | null>(null)
  const productId = ref<string | null>(null)
  const variants = ref<Variants | null>(null)
  const howToReturn = ref<HowToReturn | null>(null)
  const similarProducts = ref<SimilarProducts | null>(null)
  const calculus = ref<CalculusResponse | null>(null)
  const calculusSummary = computed(() => {
    if (!order.value || !calculus.value) return null
    return order.value.currentStatus === OrderStatusEnum.CLOSED
      ? calculus.value.currentSummary
      : calculus.value.newSummary
  })
  const elynSettings = ref<ElynSettings | null>(null)
  const exchangeType = ref<ExchangeType>(ExchangeType.VARIANTS)
  const featuredReturnOptions = ref<ReturnOptionEnum[]>([])
  const otherReturnOptions = ref<ReturnOptionEnum[]>([ReturnOptionEnum.REFUND])

  watch(
    [order, calculus, returnPolicy],
    ([newOrder, newCalculus, newReturnPolicy]) => {
      if (!newOrder || !newCalculus || !newReturnPolicy) return
      featuredReturnOptions.value = OrderManager.getOrderFeaturedReturnOptions(
        newOrder,
        newCalculus,
        newReturnPolicy,
      )
      otherReturnOptions.value = OrderManager.getOrderOtherReturnOptions(
        newOrder,
        newCalculus,
        newReturnPolicy,
      )
    },
  )

  watch([lineItem, productId], async ([newLineItem, newProductId]) => {
    variants.value = null
    if (!newLineItem || !newProductId || !order.value) return
    if (
      featuredReturnOptions.value.includes(ReturnOptionEnum.VARIANT_EXCHANGE) ||
      exchangeType.value === ExchangeType.DIFF_PRICE
    ) {
      const params: GetProductVariantsParams = {
        isDiffPriceExchange: exchangeType.value === ExchangeType.DIFF_PRICE,
        lineItemId: newLineItem.id,
        productId: newProductId,
      }
      variants.value = await apiService.getVariants(order.value.id, params)
    }
  })

  const tracking = useTracking()
  watch([order, tenant, returnPolicy], async () => {
    if (tenant.value && order.value) {
      tracking.identify(`${tenant.value.id}-${order.value.id}`, {
        tenantId: tenant.value.id,
        tenant: tenant.value.name,
        returnPoliciyId: returnPolicy.value?.id,
      })
    }
  })

  return {
    isAppLoading,
    isLoggedIn,
    orderRaw,
    order,
    returnPolicy,
    howToReturn,
    tenant,
    calculus,
    calculusSummary,
    lineItem,
    productId,
    variants,
    similarProducts,
    elynSettings,
    featuredReturnOptions,
    otherReturnOptions,
    exchangeType,
  }
})
