import axios from 'axios'
import type {
  AdyenConfirmPaymentPayload,
  CalculusPayload,
  CalculusResponse,
  ElynSettings,
  ExtendPeriodDatePayload,
  Facets,
  GetDifferentPriceProductsParams,
  GetProductVariantsParams,
  GetProductsResponse,
  GetTenantStores,
  HowToReturn,
  OrderRaw,
  PatchOrderPayload,
  PostReturnRequestPayload,
  PostReturnRequestResponse,
  PreparePaymentResponse,
  RefreshTokenResponseData,
  ReturnLabelInfo,
  ReturnPolicy,
  SimilarProducts,
  StripeConfirmPaymentPayload,
  Tenant,
  TenantStore,
  TokenResponseData,
  ValidateReturnPayload,
  Variants,
} from 'src/interfaces'
import {DevEnvManager} from './utils/dev-env-manager'

export let BASE_URL = import.meta.env.VITE_ELYN_HOST

if (import.meta.env.VITE_ENVIRONMENT !== 'production') {
  BASE_URL = DevEnvManager.getCurrentBaseUrl()
}

export const axiosInstance = axios.create({baseURL: BASE_URL})

// Intercept request to specify camelcase for all requests
axiosInstance.interceptors.request.use(config => {
  config.headers['Accept'] = 'application/vnd.elyn.camelcase+json'

  if (config.method === 'post' || config.method === 'put') {
    config.headers['Content-Type'] = 'application/vnd.elyn.camelcase+json'
  }

  return config
})

class ApiService {
  async getToken(
    email: string,
    displayedOrderId: string,
    tenantName: string,
  ): Promise<TokenResponseData> {
    const response = await axiosInstance.post('/return_interface/token', {
      email,
      tenantName,
      displayedOrderId,
    })
    return response.data
  }

  async refreshToken(refreshToken: string): Promise<RefreshTokenResponseData> {
    // ! Do not use axiosInstance here
    const response = await axios.post(
      `${BASE_URL}/return_interface/token/refresh`,
      {
        refresh: refreshToken,
      },
    )
    return response.data
  }

  async getTenantByName(tenantName: string): Promise<Tenant> {
    const response = await axiosInstance.get(
      `${BASE_URL}/public_tenants/${encodeURI(tenantName)}`,
    )
    return response.data
  }

  async getSimilarProducts(
    tenantId: number,
    limit: number,
    price: number,
    startKey?: string,
  ): Promise<SimilarProducts> {
    const response = await axiosInstance.get(
      '/tenants/' + tenantId + '/similar_products',
      {
        params: {
          limit,
          price,
          startKey,
        },
      },
    )
    return response.data
  }

  async getDifferentPriceProducts(
    orderId: number,
    params: GetDifferentPriceProductsParams,
  ): Promise<GetProductsResponse> {
    const response = await axiosInstance.get(
      `/orders/${orderId}/different_price_products`,
      {
        params: {
          ...params,
          line_item_id: params.lineItemId,
          min_price: params.minPrice,
          max_price: params.maxPrice,
          order_by: params.orderBy,
          category_cms_ids: params.categoryCmsIds,
        },
        paramsSerializer: {
          indexes: null,
        },
      },
    )
    return response.data
  }

  async getRecommendations(
    orderId: number,
    lineItemId: number,
    limit = 20,
  ): Promise<GetProductsResponse> {
    const response = await axiosInstance.get(
      `/orders/${orderId}/recommendations`,
      {
        params: {
          line_item_id: lineItemId,
          limit,
        },
      },
    )
    return response.data
  }

  async getCmsFacets(orderId: number, lineItemId: number): Promise<Facets> {
    const response = await axiosInstance.get(`/orders/${orderId}/cms_facets`, {
      params: {
        line_item_id: lineItemId,
      },
    })
    return response.data
  }

  async getVariants(
    orderId: number,
    payload: GetProductVariantsParams,
  ): Promise<Variants> {
    const response = await axiosInstance.get(
      '/orders/' + orderId + '/products_variants',
      {
        params: {
          is_diff_price_exchange: payload.isDiffPriceExchange,
          line_item_id: payload.lineItemId,
          product_id: payload.productId,
        },
      },
    )
    return response.data
  }

  async getElynSettings(): Promise<ElynSettings> {
    const response = await axiosInstance.get(`/elyn_settings/last`)
    return response.data
  }

  async getOrder(orderId: number | string): Promise<OrderRaw> {
    const response = await axiosInstance.get(`/orders/${orderId}`)
    return response.data
  }

  async getReturnPolicy(orderId: number | string): Promise<ReturnPolicy> {
    const response = await axiosInstance.get(`/orders/${orderId}/return_policy`)
    return response.data
  }

  async getHowToReturn(orderId: number): Promise<HowToReturn> {
    const response = await axiosInstance.get(`/orders/${orderId}/how_to_return`)
    return response.data
  }

  async getOrderReturnLabel(orderId: number): Promise<ReturnLabelInfo> {
    const response = await axiosInstance.post(
      `/orders/${orderId}/generate_return_label`,
    )
    return response.data
  }

  async patchOrder(
    orderId: number,
    payload: PatchOrderPayload,
  ): Promise<number> {
    const response = await axiosInstance.patch('/orders/' + orderId, payload)
    return response.data
  }

  async extendPeriodDate(
    orderId: number,
    payload: ExtendPeriodDatePayload,
  ): Promise<OrderRaw> {
    const response = await axiosInstance.post(
      '/orders/' + orderId + '/extend_period_date',
      payload,
    )
    return response.data
  }

  async forceOrderInTrial(orderId: number): Promise<OrderRaw> {
    const response = await axiosInstance.post(
      '/orders/' + orderId + '/force_in_trial',
    )
    return response.data
  }

  async validateReturn(
    orderId: number,
    payload: ValidateReturnPayload,
  ): Promise<OrderRaw> {
    const response = await axiosInstance.post(
      '/orders/' + orderId + '/validate_return',
      payload,
    )
    return response.data
  }

  async postConnectionLog(orderId: number): Promise<number> {
    const response = await axiosInstance.post(
      '/return-interface-connection-logs',
      {
        orderId: orderId,
      },
    )
    return response.data
  }

  async preparePayment(
    orderId: number,
    payload: ValidateReturnPayload,
  ): Promise<PreparePaymentResponse> {
    const response = await axiosInstance.post(
      '/orders/' + orderId + '/prepare_payment',
      payload,
    )
    return response.data
  }

  async confirmPayment(
    orderId: number,
    confirmElynPaymentPayload:
      | StripeConfirmPaymentPayload
      | AdyenConfirmPaymentPayload,
  ): Promise<void> {
    await axiosInstance.post(
      '/orders/' + orderId + '/confirm_payment',
      confirmElynPaymentPayload,
    )
  }

  async getTenantStores(
    tenantId: number,
    search?: string,
  ): Promise<TenantStore[]> {
    const params: GetTenantStores = {}

    if (search) params.search = search

    const response = await axiosInstance.get(`/tenants/${tenantId}/stores`, {
      params,
    })

    return response.data
  }

  async postReturnRequest(
    orderId: number,
    payload: PostReturnRequestPayload,
  ): Promise<PostReturnRequestResponse> {
    const response = await axiosInstance.post(
      '/orders/' + orderId + '/return_request',
      JSON.stringify(payload),
    )
    return response.data
  }

  async postCalculus(
    orderId: number,
    payload: CalculusPayload,
  ): Promise<CalculusResponse> {
    const response = await axiosInstance.post(
      '/orders/' + orderId + '/calculus',
      payload,
    )
    return response.data
  }
}

export default new ApiService()
