export enum OrderStatusEnum {
  PENDING_AUTHORIZATION = 'pending_authorization',
  INITIAL_PAYMENT_EXPIRED = 'initial_payment_expired',
  PENDING_DELIVERY_TRACKING_INFORMATION = 'pending_delivery_tracking_information',
  IN_DELIVERY_SHIPPING = 'in_delivery_shipping',
  IN_TRIAL = 'in_trial',
  PENDING_DROPOFF = 'pending_dropoff',
  IN_RETURN_SHIPPING = 'in_return_shipping',
  PENDING_RETURN_CONTROL = 'pending_return_control',
  CANCELED_BY_ADMIN = 'canceled_by_admin',
  CLOSED = 'closed',
}

export enum OrderType {
  VANILLA = 'vanilla',
  OTHER = 'other',
}

export enum CustomerActionRequest {
  GET_REFUND = 'get_refund',
  GET_GIFT_CARD = 'get_giftcard',
  EXCHANGE = 'exchange',
  INSTANT_EXCHANGE = 'instant_exchange',
  KEEP = 'keep',
}

export enum ReturnLabelGeneratorEnum {
  DIRECT_COLISSIMO = 'direct_colissimo',
  MARGUERITE = 'marguerite',
  MY_FLYING_BOX = 'flying_box',
  SYMPL = 'sympl',
  MONDIAL_RELAY = 'mondial_relay',
  UPS = 'ups',
  CROSSLOG = 'crosslog',
  INSTRUCTIONS_ONLY = 'instructions_only',
}

export enum SessionStorageKeys {
  orderId = 'orderId',
  tenantName = 'tenantName',
  accessToken = 'accessToken',
  savedStore = 'savedStore',
  devStore = 'devStore',
}

export enum ReturnOptionEnum {
  VARIANT_EXCHANGE = 'VARIANT_EXCHANGE',
  DIFF_PRODUCT_EXCHANGE = 'DIFF_PRODUCT_EXCHANGE',
  GIFT_CARD = 'GIFT_CARD',
  REFUND = 'REFUND',
}

export enum ConfirmationModalStep {
  RETURN_DETAILS,
  EXCHANGE_TYPE,
  PAYMENT_FORM,
  RETURN_INFO,
}

export enum ExchangeTypeEnum {
  CLASSIC = 'classic',
  INSTANT = 'instant',
}

export enum PspEnum {
  STRIPE = 'stripe',
  ADYEN = 'adyen',
}

export enum InteractivityEnum {
  INTERACTIVE = 'interactive',
  READONLY = 'readonly',
  DISABLED = 'disabled',
}

export enum ModalsEnum {
  GENERIC_ERROR = 'generic_error',
  PDF_ERROR = 'pdf_error',
  MODIFY_RETURN = 'modify_return',
  ORDER_CONFIRMATION = 'order_confirmation',
  DECLARE_NEW_RETURN = 'declare_new_return',
}

export enum PaymentStatus {
  NONE = 'none',
  SUCCESS = 'success',
  ERROR = 'error',
}

export enum ExchangeType {
  VARIANTS = 'variants',
  DIFF_PRICE = 'diff_price',
}

export enum AdyenResponseStatusEnum {
  REFUSED = 'Refused',
  AUTHORISED = 'Authorised',
}

export enum ClientCategoriesEnum {
  RECOMMENDATIONS = 'elyn-recommends-client-side-category',
}

export enum TrackingEventEnum {
  OPTION_VARIANT_EXCHANGE = 'option_variant_exchange',
  OPTION_DIFF_PRODUCT_EXCHANGE = 'option_diff_product_exchange',
  OPTION_OTHERS = 'option_others',
  OTHER_OPTIONS_GIFTCARD_SELECTION = 'other_options_giftcard_selection',
  OTHER_OPTIONS_REFUND_SELECTION = 'other_options_refund_selection',
  RETURN_REASON_SELECTION = 'return_reason_selection',
  BUTTON_BACK = 'button_back',
  BUTTON_NEXT_RECAP_RETURN = 'button_next_recap_return',
  BUTTON_BACK_RECAP_RETURN = 'button_back_recap_return',
  BUTTON_CONFIRM_PSP = 'button_confirm_psp',
  BUTTON_MODIFY_RECAP_RETURN = 'button_modify_recap_return',
  BUTTON_CONTINUE = 'button_continue',
  BUTTON_VALIDATE_RETURN = 'button_validate_return',
  CLOSE_RECAP_RETURN = 'close_recap_return',
  IX_EXCHANGE = 'iX_exchange',
  CLASSIC_EXCHANGE = 'classic_exchange',
  DIFF_PRODUCT_SELECTION = 'diff_product_selection',
  VARIANT_OPTION_SELECTION = 'variant_option_selection',
  EXT_SEE_PRODUCT_PAGE = 'ext_see_product_page',
  BUTTON_VARIANT_PAGE_DIFF_PRODUCT = 'button_variant_page_diff_product',
  BUTTON_VARIANT_PAGE_GIFTCARD = 'button_variant_page_giftcard',
  ITEM_TO_RETURN_SELECTION = 'item_to_return_selection',
  CANCEL_RETURN = 'cancel_return',
  VALIDATE_RETURN_REQUEST = 'validate_return_request',
}

export enum TrackingPageEventEnum {
  PAGE_ITEM_SELECTION = 'page_item_selection',
  PAGE_RETURN_REASON_1 = 'page_return_reason_1',
  PAGE_RETURN_REASON_2 = 'page_return_reason_2',
  PAGE_RETURN_OPTIONS = 'page_return_options',
  PAGE_DIFF_PRODUCT = 'page_diff_product',
  PAGE_VARIANT_PRODUCT = 'page_variant_product',
  PAGE_OTHER_OPTIONS = 'page_other_options',
}
