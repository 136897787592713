import apiService from '../apiService'
import {
  CustomerActionRequest,
  ReturnOptionEnum,
  OrderStatusEnum,
} from '../enums'
import type {
  CalculusPayload,
  CalculusPayloadLineItem,
  CalculusResponse,
  ExtendPeriodDatePayload,
  LineItem,
  LineItemValidateReturnPostPayload,
  Order,
  OrderRaw,
  ReturnPolicy,
  Tenant,
} from '../interfaces'
import {getExtendedDate} from '../utils/utils'

export class OrderManager {
  static postCalculus = async (order: Order) => {
    const payload: CalculusPayload = {
      lineItems: order.lineItems.map(lineItem => {
        const serializedLineItem: CalculusPayloadLineItem = {
          id: lineItem.id,
          customerActionRequest: lineItem.lastLineItemAmount.customerRequest,
        }

        if (lineItem.exchangeLineItem) {
          serializedLineItem.newVariantId =
            lineItem.exchangeLineItem?.lineItemData.cmsVariantId
        }

        return serializedLineItem
      }),
    }
    return await apiService.postCalculus(order.id, payload)
  }

  static async extendLimitEndDate(
    order: Order,
    tenant: Tenant,
    periodType: 'trial' | 'dropoff',
  ): Promise<OrderRaw> {
    order.isLimitEndDateExtendable = false
    const payload: ExtendPeriodDatePayload = {}
    if (periodType === 'trial') {
      const newDate = getExtendedDate(
        order.trialLimitEndDate,
        tenant.trialExtraDaysAllowed,
      )
      order.trialLimitEndDate = newDate
      payload.trialLimitEndDate = newDate
    } else {
      const newDate = getExtendedDate(
        order.dropoffLimitEndDate,
        tenant.dropoffExtraWorkingDaysAllowed,
      )
      order.dropoffLimitEndDate = newDate
      payload.dropoffLimitEndDate = newDate
    }
    return apiService.extendPeriodDate(order.id, payload)
  }

  static isOrderConfirmable(orderStatus: OrderStatusEnum) {
    if (!orderStatus) return false
    return ![
      OrderStatusEnum.IN_RETURN_SHIPPING,
      OrderStatusEnum.PENDING_RETURN_CONTROL,
      OrderStatusEnum.PENDING_DROPOFF,
      OrderStatusEnum.CLOSED,
    ].includes(orderStatus)
  }

  static orderHasSomethingToReturn(order: Order) {
    return !order.lineItems.every(
      (lineItem: LineItem) =>
        lineItem.lastLineItemAmount.customerRequest ===
        CustomerActionRequest.KEEP,
    )
  }

  static orderHasRefundsOrExchange(order: Order) {
    return order.lineItems.some((lineItem: LineItem) =>
      [
        CustomerActionRequest.EXCHANGE,
        CustomerActionRequest.INSTANT_EXCHANGE,
        CustomerActionRequest.GET_REFUND,
      ].includes(lineItem.lastLineItemAmount.customerRequest),
    )
  }

  static orderHasSomethingToExchange(order: Order) {
    return order.lineItems.some((lineItem: LineItem) =>
      [
        CustomerActionRequest.EXCHANGE,
        CustomerActionRequest.INSTANT_EXCHANGE,
      ].includes(lineItem.lastLineItemAmount.customerRequest),
    )
  }

  static orderHasItemsReturnable(order: Order) {
    return order.lineItems.some(
      (lineItem: LineItem) =>
        lineItem.isShipped &&
        !lineItem.lineItemData.isNonReturnable &&
        !lineItem.lineItemData.isBundleProduct &&
        lineItem.lastLineItemAmount.customerRequest ===
          CustomerActionRequest.KEEP,
    )
  }

  static orderHasMultiReturnAvailable(
    order: Order,
    returnPolicy: ReturnPolicy,
  ) {
    if (!order || !order.currentStatus) return false
    return (
      returnPolicy.isMultipleReturnEnabled &&
      [
        OrderStatusEnum.IN_RETURN_SHIPPING,
        OrderStatusEnum.PENDING_RETURN_CONTROL,
        OrderStatusEnum.CLOSED,
      ].includes(order.currentStatus)
    )
  }

  static orderHasInstantExchange(order: Order) {
    return order.lineItems.some((lineItem: LineItem) =>
      [CustomerActionRequest.INSTANT_EXCHANGE].includes(
        lineItem.lastLineItemAmount.customerRequest,
      ),
    )
  }

  static serializeLineItemsForValidation(order: Order) {
    return order.lineItems.map(
      (li: LineItem): LineItemValidateReturnPostPayload => {
        return {
          id: li.id,
          customerActionRequest: li.lastLineItemAmount.customerRequest,
          newVariantId: li.exchangeLineItem
            ? li.exchangeLineItem.lineItemData.cmsVariantId
            : undefined,
          returnReasons: li.returnReasons,
        }
      },
    )
  }

  static getOrderFeaturedReturnOptions(
    order: Order,
    calculus: CalculusResponse,
    returnPolicy: ReturnPolicy,
  ): ReturnOptionEnum[] {
    if (
      order.hasDiscounts ||
      calculus.currentSummary.additionalyDiscountBalance ||
      order.initialOrderId
    ) {
      return []
    }
    const options = []
    if (
      returnPolicy.isFamilyExchangeReturnInterface ||
      returnPolicy.hasDifferentPriceExchange
    ) {
      options.push(ReturnOptionEnum.DIFF_PRODUCT_EXCHANGE)
    }
    if (returnPolicy.isExchangeReturnInterface)
      options.push(ReturnOptionEnum.VARIANT_EXCHANGE)
    return options
  }

  static getOrderOtherReturnOptions(
    order: Order,
    calculus: CalculusResponse,
    returnPolicy: ReturnPolicy,
  ): ReturnOptionEnum[] {
    const options = [ReturnOptionEnum.REFUND]

    if (
      !order.hasDiscounts &&
      !calculus.currentSummary.additionalyDiscountBalance &&
      !order.initialOrderId &&
      returnPolicy.isGiftCardEnabled
    ) {
      options.push(ReturnOptionEnum.GIFT_CARD)
    }
    return options
  }
}
