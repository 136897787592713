import mixpanel from 'mixpanel-browser'
import * as amplitude from '@amplitude/analytics-browser'
import posthog from 'posthog-js'

export default function useTracking() {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const identify = (userId: string, metadata: Record<string, any> = {}) => {
    mixpanel.identify(userId)
    mixpanel.people.set(metadata)

    const identifyEvent = new amplitude.Identify()
    identifyEvent.set('distinct_id', userId)
    Object.entries(metadata).forEach(([key, value]) => {
      identifyEvent.set(key, value)
    })
    amplitude.identify(identifyEvent)
    amplitude.setUserId(userId)

    posthog.identify(userId, metadata)
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const track = (event: string, properties: Record<string, any> = {}) => {
    mixpanel.track(event, properties)
    amplitude.track(event, properties)
    posthog.capture(event, properties)
  }

  return {identify, track}
}
